@import '../../../variables';

.projects-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    padding: 0 1.5rem 2rem;

    @media #{$desktop} {
        margin: 0 -2rem;
    }

    li {
        display: flex;
        width: 100%;
        padding: 1rem;

        @media #{$desktop} {
            padding: 2rem;
        }

        @media #{$tablet} {
            width: 50%;
        }
    }
}

.box-project {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);

    @media #{$mobile} {
        flex-direction: column;
    }

    &__img {
        display: block;

        @media #{$desktop} {
            overflow: hidden;
            width: 50%;
        }

        @media #{$mobile-sm} {
            height: calc(100vw - 3rem);
        }

        &:hover {
            @media #{$desktop} {
                img {
                    transform: scale(1.05);
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            @media #{$desktop} {
                transition: transform .3s ease;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        padding: 1.5rem;
        background: $blue-dark;
        text-align: center;

        @media #{$desktop} {
            width: 50%;
        }
    }

    &__title {
        margin-bottom: .5rem;
        font-size: 1.5rem;
        font-weight: $fw-medium;
    }

    &__desc {
        display: flex;
        flex-grow: 1;
        margin-bottom: 1rem;
        font-size: 1rem;
        letter-spacing: 0.2px;
        font-weight: $fw-regular;
        color: $beige-dark;
    }

    &__label {
        letter-spacing: 1px;
        font-weight: $fw-medium;
        color: $beige-dark;
    }

    &__stack {
        margin-bottom: 1rem;
    }

    .button {
        margin-top: 1.5rem;
    }
}