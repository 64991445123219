* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background: $blue;
    font-family: $base-font;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: $fw-regular;
    color: $beige;
}

#root {
    width: 100%;
    overflow-x: hidden;
    min-height: 100vh;
}

figure {
    margin: 0;
    padding: 0;
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

button,
input,
textarea,
select {
    padding: 0;
    border-radius: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    outline: none;
    color: $beige;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: $beige;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

img {
    max-width: 100%;
}

p {
    margin: 0;
    padding: 0;
}