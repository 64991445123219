.page-home {
    &.is-previewing {
        position: relative;
        overflow: hidden;
        height: 100vh;
    }

    &__main {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
}
