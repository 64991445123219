@import '../../../variables';

form {
    width: 100%;
    max-width: 300px;

    .message {
        margin-bottom: 1rem;
    }

    .input-field {
        position: relative;
        margin-bottom: 1rem;
        padding-bottom: 1px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
        }

        &::before {
            background: rgba(0, 0, 0, 0.2);
        }

        &::after {
            background: $beige-dark;
            transition: transform 0.3s ease;
            transform: translateX(-50%) scaleX(0);
        }

        &.is-focused {
            &::after {
                transform: translateX(0) scaleX(1);
            }

            label {
                transform: translate(-25%, -50%) scale(0.5);
            }
        }
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
        line-height: 2.5rem;
        color: $beige-dark;
        transition: transform 0.3s ease;
        pointer-events: none;
    }

    .asterisk {
        color: $asterisk;
    }

    input {
        display: block;
        width: 100%;
        margin: 0;
        height: 2.5rem;
        font-size: 1rem;
    }

    textarea {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0.5rem 0;
        font-size: 1rem;
        line-height: 1;
    }

    .button-field {
        text-align: center;
    }
}
