@import '../../../variables';
@import './carousel';

.section.certificates {
    .section__col--content {
        @media #{$desktop} {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}
