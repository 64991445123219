@import '../../../variables.scss';

.preview {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue;
    font-size: 44px;
    font-weight: $fw-black;
    color: $beige;
    z-index: 1000;
    transition: visibility 1s ease-in, opacity 1s ease-in;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;

    &.is-animated {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
    }

    div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(25px) scaleY(0.9);
    }

    span {
        &:nth-child(odd) {
            animation: preview-odd 0.8s 1 ease-in;
        }

        &:nth-child(even) {
            animation: preview-even 0.8s 1 ease-in;
        }
    }
}

@keyframes preview-odd {
    0% {
        transform: translateY(-0.5rem) scaleY(0.7);
        opacity: 0;
    }

    70% {
        transform: translateY(0) scaleY(1.2);
        opacity: 1;
    }

    90% {
        transform: translateY(0.2rem) scaleY(0.7);
        opacity: 1;
    }

    100% {
        transform: translateY(0) scaleY(1);
        opacity: 1;
    }
}

@keyframes preview-even {
    0% {
        transform: translateY(0.5rem) scaleY(0.8);
        opacity: 0;
    }

    70% {
        transform: translateY(0) scaleY(1.2);
        opacity: 1;
    }

    90% {
        transform: translateY(-0.2rem) scaleY(0.8);
        opacity: 1;
    }

    100% {
        transform: translateY(0) scaleY(1);
        opacity: 1;
    }
}
