.carousel {
    &.carousel-slider {
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;

        .control-arrow {
            background: transparent;
            opacity: 1;

            &:hover {
                background: transparent;
            }

            &.control-next {
                &::before {
                    border-left-color: $blue;
                }
            }

            &.control-prev {
                &::before {
                    border-right-color: $blue;
                }
            }
        }
    }

    .slide {
        display: flex;
        justify-content: center;
        background: transparent;

        .legend {
            bottom: 10px;
            border-radius: 50px;
            background: transparent;
            mix-blend-mode: difference;
            opacity: 1;
        }

        img {
            height: auto;
        }
    }

    .carousel-status {
        top: 20px;
        right: 20px;
        margin: 0;
        padding: 0;
        text-shadow: initial;
        mix-blend-mode: difference;
        color: #fff;
    }
}
