$blue: #505a73;
$blue-dark: #434856;
$beige: #f4eddf;
$beige-dark: #d5c8ad;
$red: #ff9c94;
$green: #9df49f;

$asterisk: $red;
$error: $red;
$success: $green;
