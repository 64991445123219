@import '../../../variables';

.page {
    .container {
        width: 100%;
        max-width: $layout-width;
        margin: 0 auto;

        &--loading {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100vh;
        }
    }

    .page-title {
        max-width: 70%;
        margin: 2rem auto;
        padding: 0 20px;
        text-align: center;

        h1 {
            margin-bottom: 1rem;
            font-size: 2rem;
            font-weight: $fw-regular;
            color: $beige;
        }

        h2 {
            font-size: 1rem;
            letter-spacing: 0.5px;
            font-weight: $fw-regular;
            color: $beige-dark;
        }
    }

    .row {
        @media #{$desktop} {
            display: flex;
        }
    }

    .col {
        padding: 0 20px;

        @media #{$desktop} {
            width: 50%;
            padding-right: 4rem;
            padding-left: 4rem;
            padding-bottom: 50px;
        }
    }
}
