@import '../../../variables';

.button {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-top: 2rem;
    width: 7rem;
    max-width: 100%;
    height: 2rem;
    border-radius: 25px;
    border: 1px solid $beige-dark;
    background: transparent;
    font-size: 0.7rem;
    line-height: 2rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    transition: background 0.3s ease;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        background: $beige-dark;
        transition: transform 0.3s ease;
        z-index: 1;
    }

    &::before {
        left: 0;
        right: 50%;
        transform: translateY(-100%);
    }

    &::after {
        left: 50%;
        right: 0;
        transform: translateY(100%);
    }

    .button-label,
    .button-hover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        span {
            position: absolute;
            top: 0;
            transition: transform 0.3s ease;

            &:first-child {
                right: 50%;
            }

            &:last-child {
                left: 50%;
            }
        }
    }

    .button-label {
        color: $beige;

        span {
            &:first-child {
                transform: translateY(0);
            }

            &:last-child {
                transform: translateY(0);
            }
        }
    }

    .button-hover {
        color: #000;

        span {
            &:first-child {
                transform: translateY(-100%);
            }

            &:last-child {
                transform: translateY(100%);
            }
        }
    }

    &:hover {
        &::before,
        &::after {
            @media #{$desktop} {
                transform: translateY(0);
            }
        }

        .button-label {
            span {
                &:first-child {
                    @media #{$desktop} {
                        transform: translateY(100%);
                    }
                }

                &:last-child {
                    @media #{$desktop} {
                        transform: translateY(-100%);
                    }
                }
            }
        }

        .button-hover {
            span {
                &:first-child {
                    @media #{$desktop} {
                        transform: translateY(0);
                    }
                }

                &:last-child {
                    @media #{$desktop} {
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}
