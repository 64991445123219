@import '../../../variables';

.bio {
    &--full {
        margin-bottom: 2rem;
    }

    &__general {
        display: flex;
        align-items: center;

        &__content {
            p {
                padding-top: 0.5rem;
                padding-left: 2rem;
                font-size: 0.8rem;
                line-height: 1.5;
                letter-spacing: 0.5px;
                color: $beige-dark;
            }
        }
    }

    &__name {
        padding-left: 2rem;
        font-size: 2rem;
        letter-spacing: 1px;
        font-weight: $fw-bold;
        text-transform: uppercase;
    }

    &__photo {
        max-width: 200px;
        border-radius: 50%;
        overflow: hidden;

        img {
            display: block;
            height: auto;
            object-fit: cover;
            object-position: center;
        }
    }

    &__content {
        padding-top: 2rem;
    }

    &__info {
        padding-bottom: 1.5rem;
    }
}
