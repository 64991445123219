@import '../../../variables';

.logo {
    max-width: 200px;
    z-index: 2;

    @media #{$desktop} {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    img {
        display: block;
        width: 100%;
    }
}
