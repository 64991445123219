@import '../../../variables';

.section {
    position: relative;
    background: $blue;
    z-index: 3;

    &--reverse {
        .section__row {
            @media #{$desktop} {
                flex-direction: row-reverse;
            }
        }
    }

    &--dark {
        background: $blue-dark;
    }

    &--loading {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 300px;
    }

    &__container {
        @media #{$desktop} {
            display: flex;
            justify-content: center;
        }
    }

    &__row {
        width: 100%;
        max-width: 1440px;

        @media #{$desktop} {
            display: flex;
            align-items: center;
        }
    }

    &__col {
        padding: 20px;

        @media #{$desktop} {
            width: 50%;
            padding: 100px 30px;
        }

        &--text {
            padding-top: 40px;
            padding-bottom: 40px;
            text-align: center;

            @media #{$desktop} {
                padding-top: 100px;
                padding-bottom: 100px;
            }

            h1,
            h2 {
                margin-bottom: 1rem;
                font-size: 2rem;
                font-weight: $fw-regular;
                color: $beige;
            }

            h3 {
                font-size: 1rem;
                letter-spacing: 0.5px;
                font-weight: $fw-regular;
                color: $beige-dark;
            }
        }

        &--content {
            & > div {
                max-width: 100%;
            }

            &--center {
                &-desk {
                    @media #{$desktop} {
                        display: flex;
                        justify-content: center;
                    }
                }
                &-mob {
                    @media #{$mobile} {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            padding: 20px;
        }

        a {
            display: block;
            width: 100%;
            max-width: 130px;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}
