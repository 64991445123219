@import '../../../variables';

.project-content {
    @media #{$desktop} {
        padding-bottom: 3rem;
    }

    &__columns {
        padding: 2rem 2rem 0;

        @media #{$desktop} {
            display: flex;
            width: 100%;
            max-width: $layout-width;
            margin: 0 auto;
        }

        &__img {
            @media #{$desktop} {
                width: 50%;
                padding-right: 3rem;
                border-right: 1px dashed rgba($beige-dark, .2);
            }

            img {
                width: 100%;
            }
        }

        &__content {
            @media #{$desktop} {
                width: 50%;
                padding-left: 3rem;
            }

            @media #{$mobile} {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 2rem;
                padding-bottom: 3rem;
                text-align: center;
            }

            & > * {
                margin-top: 1rem;
            }
        }
    }

    &__logo {
        max-width: 200px;

        img {
            display: block;
            width: 100%;
        }
    }

    &__label {
        color: $beige-dark;
    }

    &__values {
        @media #{$desktop} {
            list-style: circle inside;
        }
    }

    &__date {
        margin-top: 2rem;
    }

    &__btn {
        display: flex;
        justify-content: center;
        padding-bottom: 1rem;

        .button {
            margin-top: 0;
        }
    }

    &__buttons {
        margin-top: 0;

        .button {
            &:not(:last-of-type) {
                margin-right: .5rem;
            }
        }
    }
}