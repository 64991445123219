@import '../../../variables';

.main-scroll {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    width: 18px;
    height: 28px;
    border-radius: 9px;
    transform: translateX(-50%);
    z-index: 2;

    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        top: 0;
        bottom: 0;
        left: calc(50% - 0.5px);
        display: block;
        width: 1px;
        background: $beige-dark;
        opacity: 0.2;
    }

    &::after {
        top: 4px;
        left: calc(50% - 2px);
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $beige-dark;
        animation: scroll 2s infinite ease;
    }
}

@keyframes scroll {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    90% {
        transform: translateY(12px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 0;
    }
}
