@import '../../../variables';

.toggle-nav {
    position: fixed;
    top: 30px;
    display: block;
    width: 30px;
    height: 18px;
    z-index: 500;
    transition: transform 0.3s ease;
    cursor: pointer;

    @media #{$desktop} {
        right: 30px;
    }

    @media #{$mobile} {
        left: 30px;
    }

    span {
        position: absolute;
        right: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        background: $beige;
        transition: transform 0.3s ease;

        &:first-child,
        &:last-child {
            background: transparent;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 60%;
                background: $beige;
                transition: transform 0.3s ease;
                transform: translateX(0) scaleX(1);
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
            }
        }

        &:first-child {
            top: 0;
            transform: scaleX(1);
        }

        &:nth-child(2) {
            top: 50%;
            margin-top: -1px;

            @media #{$desktop} {
                transform: translateX(12.5%) scaleX(0.75);
            }

            @media #{$mobile} {
                transform: translateX(-12.5%) scaleX(0.75);
            }
        }

        &:last-child {
            bottom: 0;
            transform: translateX(25%) scaleX(0.5);

            @media #{$desktop} {
                transform: translateX(25%) scaleX(0.5);
            }

            @media #{$mobile} {
                transform: translateX(-25%) scaleX(0.5);
            }
        }
    }

    &:not(.is-active) {
        &:hover {
            span {
                &:nth-child(2),
                &:last-child {
                    @media #{$desktop} {
                        transform: translateX(0) scaleX(1);
                    }
                }
            }
        }
    }

    &.is-active {
        transform: scale(0.7);

        span {
            &:nth-child(2) {
                transform: scaleX(0);
            }

            &:first-child,
            &:last-child {
                background: transparent;
            }

            &:first-child {
                transform: translateY(2px) scaleX(1);

                &::before {
                    transform: rotate(45deg) translateX(0) scaleX(1);
                }

                &::after {
                    transform: rotate(-45deg) translateX(0) scaleX(1);
                }
            }

            &:last-child {
                transform: translateY(-2px) scaleX(1);

                &::before {
                    transform: rotate(-45deg) translateX(0) scaleX(1);
                }

                &::after {
                    transform: rotate(45deg) translateX(0) scaleX(1);
                }
            }
        }
    }
}

.nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200;
    visibility: hidden;
    transition: visibility 0s 1s;

    &__overlay,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
    }

    &__overlay {
        width: 150vw;
        height: 150vw;
        background: rgba(0, 0, 0, 0.7);
        transition: transform 1s ease 0.2s;
        z-index: 1;

        @media #{$mobile} {
            width: 250vw;
            height: 250vw;
        }
    }

    &::after {
        width: 80vw;
        height: 80vw;
        background: rgba(0, 0, 0, 0.8);
        transition: transform 0.5s ease;
        z-index: 2;
    }

    &-list {
        position: absolute;
        top: 50%;
        left: 50%;
        justify-content: center;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.3s ease 0.2s;
        z-index: 3;

        li {
            text-align: center;
        }

        a {
            display: block;
            padding: 0.5rem 0;
            font-size: 1.5rem;
            letter-spacing: 0.8px;
        }
    }

    &.is-active {
        visibility: visible;
        transition: visibility 0s;

        .nav__overlay,
        &::after {
            transform: translate(-50%, -50%) scale(1);
        }

        .nav__overlay {
            transition: transform 1s ease;
        }

        &::after {
            transition: transform 0.5s ease 0.5s;
        }

        .nav-list {
            transform: translate(-50%, -50%) scale(1);
            transition: transform 0.3s ease 0.7s;
        }
    }
}
