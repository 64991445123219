@import '../../../variables';

.message {
    padding: 0.2rem;
    font-size: 0.9rem;
    font-weight: $fw-medium;
    text-align: center;

    &--error {
        color: $error;
    }

    &--success {
        color: $success;
    }
}
