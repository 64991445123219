table {
    width: 100%;

    th,
    td {
        width: 50%;
        padding: 5px 0;
        line-height: 1.5;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    th {
        font-weight: $fw-regular;
        text-align: left;
        color: $beige-dark;
    }

    td {
        white-space: nowrap;
        text-align: right;
        color: $beige;
    }
}