@import '../../../variables.scss';

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;

    div {
        display: block;
        width: 4px;
        height: 4px;
        margin: 5px;
        border-radius: 50%;
        background: $beige;
        box-shadow: 0 0 2px #fff;

        &:first-child {
            animation: loader-dot 1.5s infinite;
        }

        &:nth-child(2) {
            animation: loader-dot 1.5s infinite 0.5s;
        }

        &:last-child {
            animation: loader-dot 1.5s infinite 1s;
        }
    }
}

@keyframes loader-dot {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
