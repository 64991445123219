@import '../../../variables';

.page-contact {
    display: flex;
    min-height: 100vh;

    .container {
        width: 100%;
    }

    &__column {
        display: flex;
        justify-content: center;
        margin-bottom: 3rem;

        @media #{$desktop} {
            margin-bottom: 6rem;
        }
    }

    &__social {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            margin: 1rem;
            padding: 0;
        }

        .button {
            display: block;
            margin: 0;
        }
    }
}