@import '../../../variables';

.block {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    &__title {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: $fw-regular;
        text-transform: lowercase;
        color: $beige-dark;
    }

    &__content {
        position: relative;
        font-size: 1rem;

        ul {
            list-style: circle;
            padding-left: 1.3rem;
        }

        strong {
            font-size: 1.05rem;
            font-weight: $fw-bold;
        }
    }

    blockquote {
        margin: 0;
        padding: 1rem;
        border-left: 4px solid $beige-dark;
        background: $blue-dark;
        color: rgba($beige, .8);
    }
}
